import React, { useState, useRef, useEffect } from "react";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import { api_Routes } from "../api_Routes";
import { Helper } from "../tools/Helper";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import loginimage from "../Assets/loginomage.png"
import CircularProgress from '@mui/material/CircularProgress';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import LoginSvg from '../Assets/Svg/login-logo.svg'
const ResetPassword = () => {
    const [showPassword, setShowPassword] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [isloading, setisloading] = useState(false);
    const handleTogglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };
    const handleChange = (key, value) => {
        setFormData((prev) => ({ ...prev, [key]: value }));
    };
    const [formData, setFormData] = useState({
        password: "",
        password_confirmation: "",
    });
    const handleSubmit = async () => {
        setisloading(true)

        const requiredFields = [
            "password",
            "password_confirmation",
        ];
        const missingFields = requiredFields.filter((field) => !formData[field]);

        if (missingFields.length > 0) {
            enqueueSnackbar(
                `Please fill in all required fields: ${missingFields.join(", ")}`,
                {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                }
            );
            setisloading(false);
            return;
        }
        const email = localStorage.getItem('email');
        var form_data = new FormData();

        // var updatedFormData = { ...formData };
        // var valueTemp = "";
        form_data.append('password', formData.password);
        form_data.append('password_confirmation', formData.password_confirmation);
        form_data.append('email', email);
        // form_data.append('user_id', user_id);


        const { response, message } = await Helper.Post({
            url: api_Routes.Auth.ResetPassword,
            data: form_data,
            hasToken: true,
        });

        if (response) {

            enqueueSnackbar(message, {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });

            navigate(`/home`);

            setisloading(false);
        } else {
            if (typeof message === "string") {
                enqueueSnackbar(message, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                });
            }
            setisloading(false);
        }
    };




    return (
        <>
            {/* <Box
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                flexDirection="row"
                padding="40px 20px"
                sx={{ flexDirection: { xs: "column", sm: "column", md: "row" } }}
            >
                <Box
                    sx={{ display: { xs: "none", lg: "block" }, marginBottom: "4rem" }}
                >
                    <img src={loginimage} alt="Loggin" style={{ marginTop: "40px" }} />
                </Box>
                <Box
                    sx={{
                        border: "1px solid #e9e9e9",
                        borderRadius: "14px",
                        padding: "90px 30px",
                        background: "#F1F5FD",
                        width: { xs: "80%", sm: "60%", md: "50%", lg: "30%" }
                    }}
                >
                    <Box textAlign="center">
                        <Typography
                            sx={{
                                fontWeight: "600",
                                lineHeight: "60px",
                                fontSize: "40px",
                                color: "#004571",
                            }}
                        >
                            Create New Password
                        </Typography>
                    </Box>
                    <Box sx={{ marginTop: "20px" }}>
                        <TextField
                            label="New Password "
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            InputLabelProps={{
                                style: { color: "#434343", fontSize: "15px" },
                            }}
                            onChange={(e) => handleChange("password", e.target.value)}

                            sx={{
                                backgroundColor: "white",
                                marginTop: "10px",
                                "& .MuiInputBase-root": { color: "black !important" },
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px",
                                    "&:hover fieldset": { borderColor: "#3F78E0 !important" },
                                    "&.Mui-focused fieldset": { borderColor: "#3F78E0 !important" },
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleTogglePasswordVisibility}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}

                        />
                    </Box>
                    <Box sx={{ marginTop: "20px" }}>
                        <TextField
                            label="confirm New Password "
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            InputLabelProps={{
                                style: { color: "#434343", fontSize: "15px" },
                            }}
                            onChange={(e) => handleChange("password_confirmation", e.target.value)}

                            sx={{
                                backgroundColor: "white",
                                marginTop: "10px",
                                "& .MuiInputBase-root": { color: "black !important" },
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px",
                                    "&:hover fieldset": { borderColor: "#3F78E0 !important" },
                                    "&.Mui-focused fieldset": { borderColor: "#3F78E0 !important" },
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleTogglePasswordVisibility}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}

                        />
                    </Box>

                    <Box>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#3F78E0",
                                width: "100%",
                                marginTop: "20px",
                                padding: "10px 100px",
                                borderRadius: "8px",
                                color: "white",
                                fontSize: "20px",
                                lineHeight: "30px",
                                fontWeight: "600",
                                // "&:hover": { backgroundColor: "#00B200", color: "white" },
                            }}
                            onClick={() => handleSubmit()}
                        >
                            {isloading ? <CircularProgress size={22} sx={{ color: "white" }} /> : "save"}
                        </Button>
                    </Box>



                </Box>
            </Box> */}
            <Box sx={{ marginBlock: "30px", paddingTop: { xs: "40px", sm: "80px" }, paddingBottom: "10px", background: "#E5EDFF", borderRadius: "20px", marginInline: { xs: "10px", md: "50px", lg: "100px" }, paddingInline: { xs: "20px", md: "50px", lg: "70px" } }} >
                <Box sx={{

                    display: "flex", flexDirection: { xs: "column", sm: "row" }, justifyContent: { xs: "center", sm: "space-around" }, alignItems: { xs: "center", sm: "baseline" },
                    marginBottom: "20px",
                }}>
                    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: { xs: "center", sm: "flex-start" } }}>
                        <img src={LoginSvg} alt="" />
                        <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
                            <Typography sx={{ fontWeight: "600", fontSize: { xs: "25px", sm: "20px", lg: "35px" }, lineHeight: "50px", color: "#5F5F5F", textAlign: { xs: "center", sm: "left" }, width: "100%" }}>
                                Create new password
                            </Typography>
                            <Box sx={{ display: "flex", justifyContent: { xs: "center", sm: "flex-start" }, alignItems: { xs: "center", sm: "flex-start" } }}>
                                <Typography sx={{ fontWeight: "600", fontSize: { xs: "14px", sm: "16px", lg: "20px" }, lineHeight: "30px", color: "#5F5F5F", width: { xs: "65%", sm: "80%" }, textAlign: { xs: "center", sm: "left" } }}>
                                    Create new password with a mix of letters, numbers and symbols
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ width: { xs: "80%", sm: "100%", md: "60%", lg: "40%" }, display: "flex", flexDirection: "column", gap: "10px" }} >
                        <Box sx={{}}>
                            <TextField
                                label="New Password "
                                type={showPassword ? 'text' : 'password'}
                                fullWidth
                                onChange={(e) => handleChange("password", e.target.value)}
                                InputLabelProps={{
                                    style: { color: "#959595", fontSize: "18px", lineHeight: "27px", fontWeight: "500" },
                                }}

                                sx={{
                                    boxShadow: "-2px 2px 4px #00000040",
                                    borderRadius: "10px",
                                    backgroundColor: "white",
                                    marginTop: "10px",
                                    border: "0.5px solid #959595",
                                    "& .MuiInputBase-root": {
                                        color: "black !important",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        borderWidth: "10px",
                                        "&:hover fieldset": {
                                            borderColor: "#3F78E0 !important",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: "#3F78E0 !important",
                                        },
                                    },
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleTogglePasswordVisibility}
                                                edge="end"
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}

                            />
                        </Box>
                        <Box sx={{}}>
                            <TextField
                                label="confirm New Password "
                                type={showPassword ? 'text' : 'password'}
                                fullWidth

                                onChange={(e) => handleChange("password_confirmation", e.target.value)}
                                InputLabelProps={{
                                    style: { color: "#959595", fontSize: "18px", lineHeight: "27px", fontWeight: "500" },
                                }}

                                sx={{
                                    boxShadow: "-2px 2px 4px #00000040",
                                    borderRadius: "10px",
                                    backgroundColor: "white",
                                    marginTop: "10px",
                                    border: "0.5px solid #959595",
                                    "& .MuiInputBase-root": {
                                        color: "black !important",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        borderWidth: "10px",
                                        "&:hover fieldset": {
                                            borderColor: "#3F78E0 !important",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: "#3F78E0 !important",
                                        },
                                    },
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleTogglePasswordVisibility}
                                                edge="end"
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}

                            />
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    whiteSpace: 'nowrap',
                                    background: "#ECA601",
                                    padding: "10px 30px",
                                    borderRadius: '10px',
                                    // Adding inner shadow using a pseudo-element
                                    color: "#F1F5FD",
                                    position: 'relative',
                                    lineHeight: "22px",
                                    overflow: 'hidden',
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    boxShadow: "0px 3px 3px #00000040", // Outer shadow
                                    textTransform: "capitalize",
                                    '&:before': {
                                        content: '""',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        borderRadius: '0 20px 20px 0',
                                        background: 'rgba(255, 255, 255, 0.1)', // Color for the inner shadow
                                        filter: 'blur(4px)',
                                        zIndex: 1,
                                    },
                                    '&:hover:before': {
                                        background: 'rgba(255, 255, 255, 0.2)', // Darker on hover for inner shadow
                                    },
                                }}
                                onClick={handleSubmit}
                            // disabled={loading} // Disable button when loading

                            >
                                {isloading ? (
                                    <CircularProgress size={24} color="inherit" /> // Show loader when loading
                                ) : (
                                    "Confirm"
                                )}
                               
                            </Button>

                        </Box>
                    </Box>

                </Box>






            </Box>


        </>
    )

}
export default ResetPassword;