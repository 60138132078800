import React, { useState, useEffect } from "react";
import { api_Routes } from "../api_Routes";
import { Helper } from "../tools/Helper";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import { useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "../AuthContext/AuthContext";
import { useNavigate } from "react-router-dom";
import Verifyimg from "../Assets/loginomage.png";
import LoginSvg from "../Assets/Svg/login-logo.svg"

const Code = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [isloading, setisloading] = useState(false);
    const [isloadingresend, setisloadingresend] = useState(false);
    const [code, setCode] = useState(Array(5).fill(''));
    const [countdown, setCountdown] = useState(0);
    const [isloadingemail, setisloadingemail] = useState(false);

    const email = localStorage.getItem('email');

    useEffect(() => {
        const storedTimestamp = localStorage.getItem('resendTimestamp');
        const now = new Date().getTime();

        if (storedTimestamp) {
            // Calculate remaining time based on stored timestamp
            const elapsedTime = Math.floor((now - storedTimestamp) / 1000);
            const remainingTime = 60 - elapsedTime;

            if (remainingTime > 0) {
                setCountdown(remainingTime);
                startCountdown(parseInt(storedTimestamp));
            } else {
                localStorage.removeItem('resendTimestamp');
            }
        } else {
            // If no timestamp is found, start the countdown for 60 seconds
            setCountdown(60);
            localStorage.setItem('resendTimestamp', now);
            startCountdown(now);
        }
    }, []);

    const { login } = useAuth();


    useEffect(() => {
        const IsSignUp = localStorage.getItem('email');

        if (!IsSignUp) {
            navigate('/signup');
        }
    }, [navigate]);

    const handleChange = (e, index) => {
        const { value } = e.target;

        // Handle pasted value
        if (e.type === 'paste') {
            const pastedValue = e.clipboardData.getData('text');
            const newCode = [...code];

            // Fill in the OTP based on the pasted value
            for (let i = 0; i < pastedValue.length && i < newCode.length; i++) {
                if (!isNaN(pastedValue[i]) && pastedValue[i].length === 1) {
                    newCode[i] = pastedValue[i];
                }
            }
            setCode(newCode);

            // Check if all 6 digits are filled after pasting
            if (newCode.length === 5 && newCode.every(d => d)) {
                handleSubmit(newCode.join('')); // Automatically submit OTP if all digits are filled
            }

            // Move focus to the next empty input
            for (let i = 0; i < newCode.length; i++) {
                if (!newCode[i]) {
                    document.getElementById(`digit-${i}`).focus();
                    break;
                }
            }
            return; // Exit since we handled paste
        }

        // Continue with existing single character input handling
        if (isNaN(value) || value.length > 1) return; // Only allow a single numeric input

        const newCode = [...code];
        newCode[index] = value.slice(-1); // Only keep the last character
        setCode(newCode);

        // Move to the next input if the value is not empty and we're not at the last input (index < 5)
        if (value && index < 4) {
            document.getElementById(`digit-${index + 1}`).focus();
        }
        // If value is deleted and it's not the first input, move to the previous input
        else if (!value && index > 0) {
            document.getElementById(`digit-${index - 1}`).focus();
        }

        // Check if all 6 digits are filled
        if (newCode.length === 5 && newCode.every(d => d)) {
            handleSubmit(newCode.join('')); // Pass the OTP directly
        }
    };
    const handleSubmit = async (code) => {
        const email = localStorage.getItem('email');

        // const code = state?.code;
        // const codeString = code.join('');
        // if (codeString.length !== 6) {
        //     enqueueSnackbar("Please enter the 6-digit code.", { variant: "error" });
        //     return;
        // }
        setisloading(true)
        const { response, message } = await Helper.Post({

            url: api_Routes.Auth.verifyaccount,
            data: {
                email: email,
                code,

            },
            hasToken: true,
        });

        if (response) {
            setisloading(false);
            enqueueSnackbar("Code verified successfully.", { variant: "success" });
            localStorage.setItem(
                "user",
                JSON.stringify({

                    token: response.data.token,
                    username: response.data.username,
                    userphoto: response.data.photo,

                })
            );
            login(response.data.token);
            navigate('/home');

        } else {
            enqueueSnackbar(message, { variant: "error" });
            setisloading(false);
        }

    };

    const startCountdown = (startTime) => {
        const timer = setInterval(() => {
            const currentTime = new Date().getTime();
            const elapsedTime = Math.floor((currentTime - startTime) / 1000);
            const remainingTime = 60 - elapsedTime;

            if (remainingTime <= 0) {
                clearInterval(timer);
                setCountdown(0);
                localStorage.removeItem('resendTimestamp'); // Remove the timestamp after the countdown finishes
            } else {
                setCountdown(remainingTime);
            }
        }, 1000); // 1 second interval
    };
    const huandleSend = async () => {
        if (countdown > 0) {
            enqueueSnackbar("Please wait before resending the code.", { variant: "warning" });
            return;
        }
        setisloadingresend(true);
        const email = localStorage.getItem('email');
        const { response, message } = await Helper.Post({

            url: api_Routes.Auth.Resendverifyaccount,
            data: {
                email: email,


            },
            hasToken: true,
        });

        if (response) {
            setisloadingresend(false);
            enqueueSnackbar("Code send successfully.", { variant: "success" });


        } else {
            enqueueSnackbar(message, { variant: "error" });
            setisloadingresend(false);
        }

        const now = new Date().getTime();
        localStorage.setItem('resendTimestamp', now);

        // Start countdown timer for 60 seconds
        setCountdown(60);
        startCountdown(now);


        // Start the countdown with the current timestamp
    };
    return (
        <>
            <Box sx={{ marginBlock: "30px", paddingTop: { xs: "40px", sm: "80px" }, paddingBottom: "10px", background: "#E5EDFF", borderRadius: "20px", marginInline: { xs: "10px", md: "50px", lg: "100px" }, paddingInline: { xs: "20px", md: "50px", lg: "70px" } }}>
                <Box sx={{

                    display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: { xs: "center", md: "space-around" }, alignItems: { xs: "center", md: "center" },
                    gap: { xs: "25px", md: "0px" }
                }}>

                    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: { xs: "center", md: "flex-start" }, gap: "14px" }}>
                        <img src={LoginSvg} alt="" />
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <Typography sx={{ fontWeight: "600", fontSize: { xs: "25px", sm: "20px", lg: "35px" }, lineHeight: "50px", color: "#5F5F5F", textAlign: { xs: "center", md: "left" }, width: "100%" }}>
                                Verification Code
                            </Typography>
                            <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-start" }, alignItems: { xs: "center", md: "flex-start" } }}>
                                <Typography sx={{ fontWeight: "600", fontSize: { xs: "14px", sm: "20px", lg: "20px" }, lineHeight: "30px", color: "#5F5F5F", width: { xs: "65%", sm: "80%" }, textAlign: { xs: "center", md: "left" } }}>
                                    We have just send you a verification code to the following email :
                                </Typography>
                            </Box>
                            <Typography sx={{ fontWeight: "600", color: "#3F78E0", fontSize: "18px", textAlign: { xs: "center", md: "left" }, lineHeight: "21px" }}>
                                {email}
                            </Typography>
                            <Typography sx={{ color: "#959595", fontSize: "16px", lineHeight: "21px", fontWeight: "600", textAlign: { xs: "center", md: "left" } }}>
                                Please Check your Email in order to proceed with the process.
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ width: { xs: "100%", sm: "100%", md: "60%", lg: "50%" }, display: "flex", flexDirection: "column", gap: "10px" }} >
                        <Typography sx={{ color: "#474747", fontWeight: "600", fontSize: { xs: "16px", sm: "20px", lg: "20px" }, lineHeight: "30px", textAlign: "center" }}>
                            Enter Code
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: { xs: 2, sm: 3 }, marginTop: "6px" }}>
                            {code.map((digit, index) => (
                                <TextField
                                    key={index}
                                    id={`digit-${index}`}
                                    value={digit}
                                    onChange={(e) => handleChange(e, index)}
                                    onPaste={(e) => handleChange(e, index)} // Add this line to handle paste
                                    inputProps={{
                                        maxLength: 1,
                                        style: { textAlign: 'center' },
                                        onKeyDown: (e) => {
                                            // Move focus to previous input when Backspace is pressed and the input is empty
                                            if (e.key === 'Backspace' && !code[index]) {
                                                if (index > 0) {
                                                    document.getElementById(`digit-${index - 1}`).focus();
                                                }
                                            }
                                        },
                                    }}
                                    variant="outlined"
                                    sx={{
                                        boxShadow: "-2px 2px 4px #00000040",
                                        background: "white",
                                        borderRadius: "10px",
                                        width: { xs: 39, sm: 46 },
                                        '&:hover .MuiOutlinedInput-root': {
                                            // borderColor: '#3F78E0', // Change the border to green on hover
                                        },
                                        '& .MuiOutlinedInput-root': {

                                            border: 'none', // Remove border here
                                            boxShadow: 'none', // Remove any box-shadow
                                            '&.Mui-focused fieldset': {

                                            },
                                        },
                                        '& .css-1ll44ll-MuiOutlinedInput-notchedOutline': {
                                            border: 'none', // Remove the border of the notchedOutline
                                        },

                                    }}
                                />

                            ))}
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    whiteSpace: 'nowrap',
                                    background: "#ECA601",
                                    padding: "10px 30px",
                                    borderRadius: '10px',
                                    // Adding inner shadow using a pseudo-element
                                    color: "#F1F5FD",
                                    position: 'relative',
                                    lineHeight: "22px",
                                    overflow: 'hidden',
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    boxShadow: "0px 3px 3px #00000040", // Outer shadow
                                    textTransform: "capitalize",
                                    '&:before': {
                                        content: '""',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        borderRadius: '0 20px 20px 0',
                                        background: 'rgba(255, 255, 255, 0.1)', // Color for the inner shadow
                                        filter: 'blur(4px)',
                                        zIndex: 1,
                                    },
                                    '&:hover:before': {
                                        background: 'rgba(255, 255, 255, 0.2)', // Darker on hover for inner shadow
                                    },
                                }}
                                onClick={() => {
                                    if (code.length === 5 && code.every(d => d)) {
                                        handleSubmit(code.join(''));
                                    } else {
                                        enqueueSnackbar("Please enter a valid 5-digit OTP.", { variant: "error" });
                                    }
                                }}
                            // disabled={loading} // Disable button when loading

                            >
                                {isloading ? <CircularProgress size={22} sx={{ color: "white" }} /> : "Submit"}

                            </Button>

                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "100px",
                        alignItems: "center",
                        flexDirection: { xs: "column", sm: "row" }
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "16px",
                            color: "#141414CC",
                        }}
                    >
                        Didn’t receive a code?
                    </Typography>
                    <Button
                        variant="text"
                        sx={{

                            textTransform: "none",
                            marginLeft: "5px",
                            textDecoration: "underline",
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "18px",
                            // '&:hover': {
                            //     backgroundColor: countdown > 0 ? 'none' : 'none !important',
                            //     background: "#00B207",
                            //     color: "green",
                            //     boxShadow: "none" // Change hover color when active
                            // },
                            transition: 'background-color 0.3s ease', // Smooth transition for hover effect

                            color: countdown > 0 ? '#025BF4 !important' : '#025BF4',
                        }}
                        onClick={huandleSend}
                        disabled={countdown > 0} // Disable if countdown is active
                    >
                        {isloadingresend ? (
                            <CircularProgress size={16} color="inherit" /> // Show loader if loading
                        ) : (
                            countdown > 0 ? `(${countdown}s)` : "Click to Resend the Code"
                        )}
                    </Button>

                </Box>


            </Box>

        </>
    )
}
export default Code;
