import OurServices from "./components/OurServices/OurServices"
import Providers from "./components/Providers/Providers"

const user_id = JSON.parse(localStorage.getItem("user"))?.user_id
const Host = "https://subwebsite.rentchicken.net/api"
export const api_Routes = {
    Auth: {
        login: `${Host}/user/login`,
        add: `${Host}/user/register`,
        verifyaccount: `${Host}/user/register/verification`,
        Resendverifyaccount: `${Host}/user/register/resendVerification`,
        SendEmailForForgetPassword: `${Host}/user/forgetPassword/step1`,
        VerficationPasswordByEmail: `${Host}/user/forgetPassword/step2`,
        ResetPassword: `${Host}/user/forgetPassword/step3`,
        ForgetPasswordResend: `${Host}/user/forgetPassword/resendVerification`,
    },

    Search: {
        view: `${Host}/panels/search`,
        servers: `${Host}/services/search`,
    },
    AddPanel: {
        add: `${Host}/panel`,
    },
    HomePage: {
        view: `${Host}/homepage`,
    },
    Providers: {
        view: `${Host}/panels/search`,
        getOne: (id) => (`${Host}/panels/${id}`),
        add_rate: `${Host}/review`,
    },
    OurServices: {
        view: `${Host}/OurServices`,
        add: `${Host}/request`,
    },
    OurOffer: {
        view: `${Host}/offers`,
        getOne: (id) => (`${Host}/offer/${id}`),
    },
    invoice: {
        getOne: (id) => (`${Host}/GetInvoice?invoice_id=${id}`),

        pay: `${Host}/PayInvoice`,

    }
}
