import { React, useState, useEffect } from "react";
import TrustedPanel from "./TrustedPanel"
import ServiceSearch from "./ServiceFinder/ServiceSearch"
import HomePagePanel from "./HomePagePanel";
import { api_Routes } from "../../api_Routes";
import { Helper } from "../../tools/Helper";
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import UserPhoto from "../../Assets/userphoto.png"
import { Button } from "@mui/material";
const Home = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [Categories, setCategories] = useState([]);
    const [Panels, setPanels] = useState([]);
    const [country, setcountry] = useState([]);
    const [trustedPanel, settrustedPanel] = useState([]);
    const [pinup, setpinup] = useState([]);
    const [pindown, setpindown] = useState([]);
    const [homePagePanels, sethomePagePanels] = useState([]);



    useEffect(() => {
        get_Home();
    }, []);
    const get_Home = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.HomePage.view,
            hasToken: true,
            data: {
                results: 190,
            },
        });
        if (response) {
            setIsLoading(false)
            sethomePagePanels([]);
            // setCategories([]);
            // setcountry([]);
            setpindown([]);
            setpinup([]);
            setPanels([]);
            setcountry(response.data.Countries.map((ele) => ({
                id: ele.id,
                label: ele.name,
            })));
            setCategories(response.data.Categories.map((category) => ({
                id: category.id,
                label: category.name,
                subCategories: category.sub_categories.map((sub) => ({
                    id: sub.id,
                    label: sub.name,
                })),
            })));

            response.data.panelsForFilter.forEach((ele) => {
                setPanels((prev) => [
                    ...prev,
                    {
                        id: ele.id,
                        label: ele.name,

                    },
                ]);
            });

            settrustedPanel(response.data.trustedPanel.map((ele) => ({
                id: ele.id,
                name: ele.name,
                logo: ele.logo,
                uuid: ele.uuid,
            })));
            // setPanels(response.data.panelsForFilter.map((Panels) => ({
            //     id: Panels.id,
            //     label: Panels.name,
            // })));
            response.data.pinUpPanels.forEach((ele) => {
                setpinup((prev) => [
                    ...prev,
                    {
                        id: ele.id,
                        label: ele.name,
                        description: ele.description
                    },
                ]);
            });
            response.data.pinDownPanels.forEach((ele) => {
                setpindown((prev) => [
                    ...prev,
                    {
                        id: ele.id,
                        label: ele.name,
                        description: ele.description
                    },
                ]);
            });
            response.data.homePagePanels.forEach((ele) => {
                sethomePagePanels((prev) => [
                    ...prev,
                    {
                        id: ele.id,
                        name: ele.name,
                        uuid: ele.uuid,
                        logo: ele.logo,
                        description: ele.description,
                        trust: ele.trust,
                    }


                ])

            })

        } else {
            console.log(message);
            setIsLoading(false)
        }
        setIsLoading(false)
    };

    return (
        <>
            {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress sx={{ color: "blue" }} />
                </Box>
            ) : (
                <>
                    {/* <img src={UserPhoto} alt="User" style={{ border: "1px solid #296D99", borderRadius: "50%" }} />
                    <Box sx={{ display: "inline-flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "10px", padding: "10px", border: "#3F78E0 3px solid", borderRadius: "20px", boxShadow: "-6px 6px 9px #00000040" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                            <img src={UserPhoto} alt="User" style={{ border: "1px solid #296D99", borderRadius: "50%" }} />
                            <Typography sx={{ fontWeight: "500", fontSize: "18px", lineHeight: "18px", color: "#303030" }}> Mark Walberg</Typography>
                            <Typography sx={{ fontWeight: "300", fontSize: "12px", lineHeight: "18px", color: "#303030" }}>Mark.Walberg005@gmial.com</Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                            <Button sx={{ padding: "10px", background: "white", color: "#3F78E0", border: "2px solid #3F78E0", borderRadius: "15px", fontSize: "15px", lineHeight: "20px", fontWeight: "600" }}> DASHBOARD </Button>
                            <Button sx={{ padding: "10px", color: "#959595", border: "solid 2px #959595", borderRadius: "15px", fontSize: "15px", lineHeight: "20px", fontWeight: "500" }}> LOG OUT <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: "10px" }}>
                                <path d="M12.0444 13.0776V14.5834C12.0444 14.9827 11.8858 15.3657 11.6034 15.6481C11.321 15.9305 10.938 16.0891 10.5386 16.0891H4.51554C4.11618 16.0891 3.73318 15.9305 3.4508 15.6481C3.16841 15.3657 3.00977 14.9827 3.00977 14.5834V5.54874C3.00977 5.14938 3.16841 4.76639 3.4508 4.484C3.73318 4.20161 4.11618 4.04297 4.51554 4.04297H10.2375C11.069 4.04297 12.0444 4.71718 12.0444 5.54874V7.05451" stroke="#959595" stroke-width="1.20663" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M14.4531 13.0786L17.4651 10.0667L14.4531 7.05469" stroke="#959595" stroke-width="1.20663" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.22461 10.0664H16.8615" stroke="#959595" stroke-width="1.20663" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            </Button>
                        </Box>
                    </Box> */}
                    <ServiceSearch categories={Categories} countries={country} panels={Panels} pinup={pinup} pindown={pindown} />
                    <TrustedPanel trusted={trustedPanel} />
                    <HomePagePanel homePagePanels={homePagePanels} />
                </>
            )}
        </>
    )
};
export default Home;