import { React, useState, useEffect, Fragment } from "react";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button } from "@mui/material";
import Box from '@mui/material/Box';
import { api_Routes } from "../../api_Routes";
import { Helper } from "../../tools/Helper";

const TrustedPanel = ({ trusted }) => {
    const [trustedPanel, settrustedPanel] = useState([]);

    // useEffect(() => {
    //     get_Home();
    // }, []);
    // const get_Home = async () => {
    //     const { response, message } = await Helper.Get({
    //         url: api_Routes.HomePage.view,
    //         hasToken: true,
    //         data: {
    //             results: 190,
    //         },
    //     });
    //     if (response) {

    //         settrustedPanel(response.data.trustedPanel.map((ele) => ({
    //             id: ele.id,
    //             name: ele.name,
    //             logo: ele.logo
    //         })));
    //     } else {
    //         console.log(message);
    //     }
    // };

    return (
        <>
        
            <Box sx={{ marginBlock: "40px" }}>
                <Typography sx={{ fontWeight: "700", lineHeight: "40px", fontSize: "40px", color: "#004571", textAlign: "center" }}>
                    Our Trusted Providers
                </Typography>
                <Box
                    className="carousel"
                    sx={{ marginTop: "60px" }}
                    onMouseEnter={() => {
                        document.querySelector('.carousel-inner').style.animationPlayState = 'paused';
                    }}
                    onMouseLeave={() => {
                        document.querySelector('.carousel-inner').style.animationPlayState = 'running';
                    }}
                >
                    <Box className="carousel-inner">
                        {trusted.map((Tr) => (
                            <Box className="item-carosel" key={Tr.id}>
                                <img src={Tr.logo} alt={Tr.name} style={{ width: "170px", height: "90px" }} />
                            </Box>
                        ))}

                        {trusted.map((Tr) => (
                            <Box className="item-carosel" key={`repeat-${Tr.id}`}>
                                <img src={Tr.logo} alt={Tr.name} style={{ width: "170px", height: "90px" }} />
                            </Box>
                        ))}
                    </Box>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "center", marginTop: "70px" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            whiteSpace: 'nowrap',
                            background: "#ECA601",
                            padding: "10px 50px",
                            borderRadius: '10px',
                            // Adding inner shadow using a pseudo-element
                            color: "#F1F5FD",
                            position: 'relative',
                            lineHeight: "22px",
                            overflow: 'hidden',
                            fontWeight: "500",
                            fontSize: "18px",
                            boxShadow: "0px 3px 3px #00000040", // Outer shadow
                            textTransform: "capitalize",
                            '&:before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                borderRadius: '0 20px 20px 0',
                                background: 'rgba(255, 255, 255, 0.1)', // Color for the inner shadow
                                filter: 'blur(4px)',
                                zIndex: 1,
                            },
                            '&:hover:before': {
                                background: 'rgba(255, 255, 255, 0.2)', // Darker on hover for inner shadow
                            },
                        }}

                    >
                        View All Trusted

                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: "10px" }}>
                            <circle cx="12.5" cy="12.5" r="11.553" stroke="white" stroke-width="1.89394" />
                            <path d="M5.30322 12.4999H19.6972M19.6972 12.4999L14.6326 9.09082M19.6972 12.4999L14.6326 15.909" stroke="white" stroke-width="1.89394" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>



                    </Button>


                </Box>



            </Box >


        </>
    )
}
export default TrustedPanel;