import { React, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { api_Routes } from "../../api_Routes";
import { Helper } from "../../tools/Helper";
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress'
import { useParams } from "react-router-dom";
const OffersDetails = () => {
    const { offer_id } = useParams();
    const baseApi = api_Routes.OurOffer.getOne(offer_id);
    const [offer, setOffer] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        get_Offers();
    }, []);

    const get_Offers = async () => {
        let url = baseApi
        const { response, message } = await Helper.Get({
            url: url,
            hasToken: true,

        });
        if (response) {
            setIsLoading(false)
            console.log(response.data)
            setOffer({
                offer_id: response.data.offer_id,
                title: response.data.title,
                description: response.data.description,
                details: response.data.details,
                note: response.data.note,
                total_price: response.data.total_price,
                discount: response.data.discount,
                final_price: response.data.final_price,
                active: response.data.active,
                created_at: response.data.created_at,
            });
        } else {
            console.log(message);
        }
    };

    return (
        <>
            {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress sx={{ color: "#425460" }} />
                </Box>
            ) : (
                <div style={{ marginBlock: "50px" }}>

                    <Container sx={{ marginBlock: "20px" }}>
                        <Box>
                            <Typography sx={{ fontSize: { xs: "30px", md: "40px" }, fontWeight: "700", lineHeight: "48px", color: "#474747", textAlign: "center" }}>
                                OFFER DETAILS
                            </Typography>
                            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: "20px", alignItems: "center", marginTop: "20px" }}>

                                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="13.7107" height="13.7107" rx="3" transform="matrix(0.707107 0.707107 0.707107 -0.707107 -1 8.49219)" fill="#004571" />
                                </svg>
                                <Typography sx={{
                                    borderTopLeftRadius: "20px", // Adjust the radius value as needed
                                    borderBottomLeftRadius: "20px", fontWeight: "600", lineHeight: "36px", fontSize: { xs: "15px", sm: "20px", md: "30px" }, color: "white", padding: { xs: "5px", md: "10px" }, background: "#296D99"
                                }}>
                                    {offer.title}


                                </Typography>
                                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="13.7107" height="13.7107" rx="3" transform="matrix(0.707107 0.707107 0.707107 -0.707107 -1 8.49219)" fill="#004571" />
                                </svg>
                            </Box>
                        </Box>

                    </Container>
                    <Box sx={{ background: "#EBF2FF", padding: "40px 40px" }}>
                        <Container>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "40px" }}>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                    <Typography sx={{ lineHeight: "39px", color: "#474747", fontSize: "26px", fontWeight: "600", }}>
                                        Description
                                    </Typography>
                                    <Typography>
                                        {offer.details}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography sx={{ lineHeight: "39px", color: "#474747", fontSize: "26px", fontWeight: "600", }}>
                                        Note
                                    </Typography>
                                    <Typography sx={{ lineHeight: "33px", color: "#474747", fontSize: "22px", fontWeight: "600", marginLeft: "20px" }}>
                                        “ {offer.note} ”
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "space-between", borderTop: "1px solid #959595", borderBottom: "1px solid #959595", paddingBlock: "20px", alignItems: { xs: "center", md: "normal" } }}>
                                    <Typography sx={{ fontWeight: "400", fontSize: "16px", lineHeight: "24px", color: "#666666" }}>
                                        Price {offer.total_price}$
                                    </Typography>
                                    <Typography sx={{ fontWeight: "500", fontSize: "18px", lineHeight: "28px", color: "#ED1818" }}>
                                        Discount {offer.discount}
                                    </Typography>
                                    <Typography sx={{ fontWeight: "600", fontSize: "20px", lineHeight: "30px", color: "#004571" }}>
                                        Final Price  {offer.final_price}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                    <Box >
                                        <Typography


                                            sx={{ marginBottom: "10px", fontWeight: "500", fontSize: "20px", lineHeight: "25px", color: "#474747" }}
                                        >
                                            Description for main page service
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            multiline
                                            minRows={3} // sets the initial height
                                            InputLabelProps={{
                                                style: { color: "#434343", fontSize: "13px" },
                                            }}
                                            // value={formData.description}
                                            // onChange={(e) => {
                                            //     handleChange("description", e.target.value);
                                            // }}
                                            name="description"
                                            placeholder="Please enter your Description to be shown in main oage ads service "
                                            sx={{
                                                background: "white",
                                                borderRadius: "8px",
                                                "& .MuiOutlinedInput-root": {
                                                    border: "1px solid #5F5F5F",
                                                    boxShadow: "-2px 2px 4px #00000040",
                                                    borderRadius: "8px",
                                                    "& fieldset": {
                                                        border: "none"
                                                    },
                                                    "& textarea": { // target textarea styling
                                                        borderRadius: "10px",

                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box >
                                        <Typography


                                            sx={{ marginBottom: "10px", fontWeight: "500", fontSize: "20px", lineHeight: "25px", color: "#474747" }}
                                        >
                                            Panel URL Link
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            variant="outlined"

                                            // sets the initial height
                                            InputLabelProps={{
                                                style: { color: "#434343", fontSize: "13px", padding: "5px" },
                                            }}
                                            // onChange={(e) => {
                                            //     handleChange("description", e.target.value);
                                            // }}
                                            // value={formData.panel_url}


                                            // onChange={(e) => { handleChange("panel_url", e.target.value) }}
                                            name="link"
                                            placeholder="Please enter your Panel URL Link here  "
                                            sx={{
                                                background: "white",
                                                borderRadius: "8px",
                                                "& .css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input": {
                                                    padding: "10px"


                                                },
                                                "& .MuiOutlinedInput-root": {

                                                    border: "1px solid #5F5F5F",
                                                    boxShadow: "-2px 2px 4px #00000040",
                                                    borderRadius: "8px",
                                                    "& fieldset": {
                                                        border: "none"
                                                    },

                                                },
                                            }}
                                        />
                                    </Box>


                                </Box>

                            </Box>


                        </Container>

                    </Box >
                    <Box sx={{ display: "flex", justifyContent: "center", marginBlock: "30px" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                whiteSpace: 'nowrap',
                                background: "#ECA601",
                                padding: "5px 60px",
                                borderRadius: '7px',
                                // Adding inner shadow using a pseudo-element
                                color: "#F1F5FD",
                                position: 'relative',
                                lineHeight: "30px",
                                overflow: 'hidden',
                                fontWeight: "700",
                                fontSize: "1.25rem",
                                boxShadow: "0px 3px 3px #00000040", // Outer shadow
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    borderRadius: '0 20px 20px 0',
                                    background: 'rgba(255, 255, 255, 0.1)', // Color for the inner shadow
                                    filter: 'blur(4px)',
                                    zIndex: 1,
                                },
                                '&:hover:before': {
                                    background: 'rgba(255, 255, 255, 0.2)', // Darker on hover for inner shadow
                                },
                            }}
                        // onClick={() => {
                        //     handleSubmit();
                        // }}
                        >
                            {/* {IsLoading ? (
                        <CircularProgress size={22} sx={{ color: "white" }} />
                    ) : (
                        "Submit"
                    )} */}
                            submit
                        </Button>


                    </Box>

                </div >
            )}



        </>

    )



}
export default OffersDetails;