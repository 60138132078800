import { React, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { api_Routes } from "../../api_Routes";
import { Helper } from "../../tools/Helper";
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress'
import { useSnackbar } from "notistack";
import { CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Arrow from "../../Assets/Svg/offersarrow.svg"
const OurOffers = () => {
    const [Ouffers, setOuffers] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {

        get_Offers();

    }, []);

    const get_Offers = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.OurOffer.view,
            hasToken: true,
            data: {}
        });
        if (response) {
            setIsLoading(false)
            console.log(response.data)
            setOuffers([])
            response.data.forEach(ele => {
                setOuffers(prev => [...prev, {
                    offer_id: ele.offer_id,
                    title: ele.title,
                    details: ele.details,
                    note: ele.note,
                    total_price: ele.total_price,
                    discount: ele.discount,
                    final_price: ele.final_price,

                }]);
            });
        } else {
            console.log(message);
        }
    };

    const handleOfferClick = (offer_id) => {
        navigate(`/OffersDetails/${offer_id}`);
    };


    return (
        <>
            <Container sx={{
                '@media (min-width: 1200px)': {
                    maxWidth: '1400px',
                },

            }}>
                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress sx={{ color: "blue" }} />
                    </Box>
                ) : (
                    <>
                        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "16px", marginBlock: "60px" }}
                        >
                            <Typography sx={{ color: "#474747", fontSize: { xs: "20px", sm: "25px", md: "40px" }, lineHeight: "40px", fontWeight: "700" }}  >Searching For Many Services ! </Typography>
                            <Typography sx={{ color: "#474747", fontSize: "18px", lineHeight: "20px", fontWeight: "400" }}>Choose what works best dor you among all our exclusive offers.</Typography>

                        </Box>
                        {

                            Ouffers.length === 0 ?
                                <Box display="flex" justifyContent="center" sx={{ flexDirection: { sm: 'column', xs: 'column', md: 'column' }, padding: { sm: '30px 100px 230px 100px', xs: '100px 20px' }, alignItems: "center", gap: "20px" }}>


                                    <Typography sx={{ fontWeight: "700", color: "#141414", lineHeight: "32px", fontSize: { xs: "16px", sm: "25px", lg: "32px" }, marginBlock: "20px" }}> There is no Offers
                                        yet </Typography>

                                </Box>

                                :

                                <Grid container spacing={4} sx={{ marginBlock: "28px" }}>
                                    {Ouffers.map((Ouffers, index) => (
                                        <Grid item xs={12} sm={6} md={4} key={index} sx={{ position: "relative", marginTop: "20px" }} >
                                            <Card sx={{ boxShadow: "-4px 4px 4px #00000040", border: "1px solid #999696", borderRadius: "12px", width: { xs: "100%", lg: "80%" }, height: "100%", }}>
                                                <Box sx={{
                                                    background: "rgb(26,85,152)", // Fallback solid color
                                                    background: "linear-gradient(90deg, rgba(26,85,152,1) 43%, rgba(73,157,238,1) 100%)",

                                                    display: "flex", justifyContent: "center", alignItems: "center", paddingBlock: "10px"
                                                }}>
                                                    <img src={Arrow} alt="arrow" style={{ position: "absolute", top: "0%", left: "62%" }} />
                                                    <Box sx={{ position: "absolute", top: "0%", left: "62%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                        <Box>


                                                            <Typography sx={{ color: "#FFCF48", fontSize: "2rem" }}>
                                                                {Ouffers.discount}
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                            <Typography sx={{ fontSize: "14px", lineHeight: "15px", color: "white", fontWeight: "700" }}>

                                                                SPECIAL
                                                            </Typography>
                                                            <Typography sx={{ fontSize: "14px", lineHeight: "15px", color: "white", fontWeight: "700" }}>

                                                                OFFER
                                                            </Typography>
                                                        </Box>


                                                    </Box>
                                                    {/* here the logo added  */}
                                                    <Typography sx={{ fontWeight: "700", fontSize: "18px", lineHeight: "25px", color: "#FFFFFF", marginBlock: "10px" }}>

                                                        {Ouffers.title}
                                                    </Typography>
                                                    {/* <div className="label">

                                    50%
                                </div> */}
                                                </Box>
                                                <CardContent sx={{
                                                    padding: "16px 16px 8px 16px",
                                                    "&:last-child": {
                                                        paddingBottom: "8px", // Customize padding for the last child only
                                                    },
                                                }}>

                                                    <Box sx={{ borderBottom: "2px solid #80808050", paddingBottom: "10px" }}>
                                                        {Ouffers.details}
                                                    </Box>
                                                    <Box sx={{ borderBottom: "2px solid #80808050", paddingBlock: "10px", display: "flex", flexDirection: "column", }}>
                                                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                            <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                                                                <Typography sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: "400", color: "#666666" }}>
                                                                    price
                                                                </Typography>

                                                                <Typography sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: "400", color: "#666666" }}>
                                                                    {Ouffers.total_price} $
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }} >
                                                                <Typography sx={{ fontSize: "18px", lineHeight: "27px", fontWeight: "400", color: "#ED1818" }}>
                                                                    Discount
                                                                </Typography>

                                                                <Typography sx={{ fontSize: "18px", lineHeight: "27px", fontWeight: "400", color: "#ED1818" }}>
                                                                    {Ouffers.discount}
                                                                </Typography>

                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                                                            <Typography sx={{ fontSize: "20px", lineHeight: "30px", fontWeight: "400", color: "#004571" }}>
                                                                Final Price
                                                            </Typography>

                                                            <Typography sx={{ fontSize: "20px", lineHeight: "30px", fontWeight: "400", color: "#004571" }}>
                                                                {Ouffers.final_price} $
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px", marginTop: "10px" }} >
                                                        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: "10px" }}>
                                                            <Typography> <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.47332 0.892578L8.46184 4.28293L12.3233 5.1133L9.69082 8.03903L10.0888 11.9426L6.47332 10.3604L2.85782 11.9426L3.25582 8.03903L0.62332 5.1133L4.48479 4.28293L6.47332 0.892578Z" fill="#3F78E0" />
                                                            </svg>
                                                            </Typography>
                                                            <Typography>
                                                                {Ouffers.note}
                                                            </Typography>
                                                            <Typography>
                                                                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M6.47332 0.892578L8.46184 4.28293L12.3233 5.1133L9.69082 8.03903L10.0888 11.9426L6.47332 10.3604L2.85782 11.9426L3.25582 8.03903L0.62332 5.1133L4.48479 4.28293L6.47332 0.892578Z" fill="#3F78E0" />
                                                                </svg>

                                                            </Typography>
                                                        </Box>
                                                        <Box>

                                                            <Box sx={{ display: "flex", justifyContent: "center", }}>
                                                                <Button
                                                                    sx={{

                                                                        textAlign: "center",
                                                                        borderRadius: "10px",
                                                                        padding: " 4px 20px",
                                                                        background: "#3F78E0",
                                                                        color: "white",
                                                                        boxShadow: "0px 5px 5px #00000040, inset 4px 7px 5px #FFFFFF30",
                                                                        "&:hover": {
                                                                            background: "#2E5DBA", // Optional: Darken background on hover
                                                                        },
                                                                    }}
                                                                    onClick={() => navigate(`/OffersDetails/${Ouffers.offer_id}`)}
                                                                >
                                                                    Get Offer
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </Box>



                                                </CardContent>




                                            </Card>


                                        </Grid>
                                    ))}

                                </Grid>

                        }
                    </>
                )}



            </Container >


        </>


    )

}
export default OurOffers;