import { React, useRef, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { NavLink, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Logo from "../../Assets/Svg/main-logo.svg"
import { Typography } from "@mui/material";
import { useAuth } from '../../AuthContext/AuthContext';
import UserPhoto from '../../Assets/userphoto.png';
import { useState } from 'react';
import { IconButton, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
const Header = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);
    const navigate = useNavigate();
    const user = localStorage.getItem("user");
    const { logout } = useAuth();
    const [showProfile, setShowProfile] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const profileRef = useRef(null);

    const handleLogout = () => {
        setShowProfile(false);
        logout();
    };

    const toggleProfile = () => {
        setShowProfile(!showProfile);
    };

    const handleClickOutside = (event) => {
        if (profileRef.current && !profileRef.current.contains(event.target)) {
            setShowProfile(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 600);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const menuItems = (
        <Box sx={{ marginInline: "auto", display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
            <Box>
                <Box display="flex" borderRadius="3px">
                    <Link to="/home" style={{ cursor: "pointer", }} >
                        <img src={Logo} alt="Logo" />
                    </Link>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around", height: "100%" }}>
                    <NavLink to="/" className="drawer-item" activeClassName="active">
                        <span>
                            Service Finder
                        </span>
                    </NavLink>
                    <NavLink to="/Providers" className="drawer-item" activeClassName="active">
                        <span>
                            Providers
                        </span>
                    </NavLink>
                    <NavLink to="/OurServices" className="drawer-item" activeClassName="active">
                        <span>
                            Our services
                        </span>
                    </NavLink>
                    <NavLink to="/OurOffers" className="drawer-item" activeClassName="active">
                        <span>
                            Our offers
                        </span>
                    </NavLink>
                    <NavLink to="/AddPanel" className="drawer-item" activeClassName="active">
                        <span>
                            AddPanel
                        </span>
                    </NavLink>


                </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end", marginBottom: "20px" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontWeight: "500", color: "black" }}>
                        info@booksmm.com
                    </Typography>
                    <Typography sx={{ fontWeight: "500", color: "black" }}>
                        0013439874565
                    </Typography>
                </Box>
            </Box>
        </Box>
    );

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: { xs: "space-between", md: "space-around" },
                    alignItems: "center",
                    padding: 2,
                    borderRadius: "0 0 16px 16px", // Radius for left and right only
                    boxShadow: "0px 4px 5px #00000040", // Box shadow with blur: 5, y-offset: 4, and spread: 2
                    border: "2px solid #3F78E0",

                    // marginBottom: "40px"
                }}
            >
                <IconButton onClick={toggleDrawer(true)} sx={{ display: { xs: 'block', md: 'none' }, fontSize: "0", marginBlock: "auto" }}>
                    <MenuIcon fontSize="large" style={{ marginTop: "auto" }} />
                </IconButton>
                <Box display="flex" borderRadius="3px">
                    <Link to="/home" style={{ cursor: "pointer", height: "56px", }} >
                        <img src={Logo} alt="Logo" className="smallscreenlogo" />
                    </Link>
                </Box>
                <Box sx={{ display: { xs: "none", md: "flex" }, width: "60%", justifyContent: "space-around" }}>
                    <NavLink to="/" className="item" activeClassName="active">
                        <span>
                            Service Finder
                        </span>
                    </NavLink>
                    <NavLink to="/Providers" className="item" activeClassName="active">
                        <span>
                            Providers
                        </span>
                    </NavLink>
                    <NavLink to="/OurServices" className="item" activeClassName="active">
                        <span>
                            Our services
                        </span>
                    </NavLink>
                    <NavLink to="/OurOffers" className="item" activeClassName="active">
                        <span>
                            Our offers
                        </span>
                    </NavLink>
                    <NavLink to="/AddPanel" className="item" activeClassName="active">
                        <span>
                            AddPanel
                        </span>
                    </NavLink>


                </Box>
                <Box display="flex" alignItems="center">
                    {user ? (
                        <Box position="relative">
                            <img
                                src={UserPhoto}
                                alt="User"
                                style={{ border: "1px solid #296D99", borderRadius: "50%", cursor: "pointer" }}
                                onClick={toggleProfile}
                            />
                            {showProfile && (
                                <Box
                                    ref={profileRef}
                                    sx={{
                                        display: "inline-flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "10px",
                                        padding: "10px",
                                        border: "#3F78E0 3px solid",
                                        borderRadius: "20px",
                                        boxShadow: "-6px 6px 9px #00000040",
                                        position: "absolute",
                                        top: "50px",
                                        right: "0",
                                        backgroundColor: "white",
                                        zIndex: 1,
                                    }}
                                >
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                                        <img src={UserPhoto} alt="User" style={{ border: "1px solid #296D99", borderRadius: "50%" }} />
                                        <Typography sx={{ fontWeight: "500", fontSize: "18px", lineHeight: "18px", color: "#303030" }}>Mark Walberg</Typography>
                                        <Typography sx={{ fontWeight: "300", fontSize: "12px", lineHeight: "18px", color: "#303030" }}>Mark.Walberg005@gmail.com</Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                                        <Button sx={{ padding: "10px", background: "white", color: "#3F78E0", border: "2px solid #3F78E0", borderRadius: "15px", fontSize: "15px", lineHeight: "20px", fontWeight: "600" }}>DASHBOARD</Button>
                                        <Button
                                            onClick={handleLogout}
                                            sx={{ padding: "10px", color: "#959595", border: "solid 2px #959595", borderRadius: "15px", fontSize: "15px", lineHeight: "20px", fontWeight: "500" }}
                                        >
                                            LOG OUT
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: "10px" }}>
                                                <path d="M12.0444 13.0776V14.5834C12.0444 14.9827 11.8858 15.3657 11.6034 15.6481C11.321 15.9305 10.938 16.0891 10.5386 16.0891H4.51554C4.11618 16.0891 3.73318 15.9305 3.4508 15.6481C3.16841 15.3657 3.00977 14.9827 3.00977 14.5834V5.54874C3.00977 5.14938 3.16841 4.76639 3.4508 4.484C3.73318 4.20161 4.11618 4.04297 4.51554 4.04297H10.2375C11.069 4.04297 12.0444 4.71718 12.0444 5.54874V7.05451" stroke="#959595" strokeWidth="1.20663" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M14.4531 13.0786L17.4651 10.0667L14.4531 7.05469" stroke="#959595" strokeWidth="1.20663" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M7.22461 10.0664H16.8615" stroke="#959595" strokeWidth="1.20663" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <>
                            <Box>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: 'white',
                                        color: '#3F78E0',
                                        borderTopLeftRadius: { xs: 0, sm: '12px' },
                                        borderBottomLeftRadius: { xs: 0, sm: '12px' },
                                        borderTopRightRadius: { xs: '12px', sm: 0 },
                                        borderBottomRightRadius: { xs: '12px', sm: 0 },
                                        '&:hover': {
                                            backgroundColor: '#f0f0f0',
                                        },
                                        border: "2px solid #3F78E0",
                                        display: "flex",
                                        gap: "10px",
                                        padding: "10px 12px",
                                        boxShadow: '0px 4px 4px #00000040', // Apply shadow with y-offset and blur
                                    }}
                                    onClick={() => {
                                        navigate('/login');
                                    }}
                                >
                                    <Typography sx={{ textTransform: 'capitalize', fontWeight: "500", fontSize: "16px", lineHeight: "18px", color: "#3F78E0" }}>
                                        Sign In
                                    </Typography>
                                    <svg
                                        className="sign-in-arrow"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M11.2441 2.8457H14.0653C14.4394 2.8457 14.7982 2.99432 15.0627 3.25885C15.3273 3.52339 15.4759 3.88218 15.4759 4.25629V14.1304C15.4759 14.5045 15.3273 14.8633 15.0627 15.1278C14.7982 15.3924 14.4394 15.541 14.0653 15.541H11.2441"
                                            stroke="#3F78E0"
                                            strokeWidth="1.41059"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M7.7168 12.7199L11.2433 9.19346L7.7168 5.66699"
                                            stroke="#3F78E0"
                                            strokeWidth="1.41059"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M11.2428 9.19336H2.7793"
                                            stroke="#3F78E0"
                                            strokeWidth="1.41059"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </Button>
                            </Box>
                            {!isSmallScreen && ( // Only show Sign Up button if not small screen
                                <Box>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#3F78E0',
                                            borderTopRightRadius: '12px',
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0,
                                            borderBottomRightRadius: '12px',
                                            '&:hover': {
                                                backgroundColor: '#355dab',
                                            },
                                            border: "2px solid #3F78E0",
                                            display: "flex",
                                            gap: "10px",
                                            padding: "10px 12px",
                                            boxShadow: '0px 4px 4px #00000040', // Apply shadow with y-offset and blur
                                        }}
                                        onClick={() => {
                                            navigate('/signup');
                                        }}
                                    >
                                        <Typography sx={{ textTransform: 'capitalize', fontWeight: "500", fontSize: "16px", lineHeight: "18px", color: "#F1F5FD" }}>
                                            Sign Up
                                        </Typography>
                                        <svg
                                            className="sign-up-arrow"
                                            width="17"
                                            height="17"
                                            viewBox="0 0 17 17"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M5.42708 4.54075L5.42708 4.54153C5.428 5.29936 5.74855 6.01748 6.3045 6.54085C6.85915 7.06299 7.60334 7.3505 8.37209 7.35133H8.37278C8.94708 7.35133 9.51136 7.19118 9.99453 6.88726C10.478 6.58313 10.861 6.14691 11.0888 5.62915L10.5028 5.37133L11.0888 5.62915C11.3168 5.11085 11.3772 4.53804 11.2604 3.98498C11.1436 3.43227 10.8564 2.9302 10.4418 2.53992L10.003 3.00604L10.4418 2.53992C10.0277 2.15002 9.50453 1.88845 8.9404 1.78281C8.37638 1.6772 7.79138 1.73111 7.25792 1.93912C6.72424 2.14722 6.26195 2.50202 5.93376 2.9644C5.60519 3.42732 5.42708 3.97595 5.42708 4.54075ZM14.4248 15.6091H15.065V14.9689V14.2088C15.065 10.5315 12.0604 7.54619 8.37278 7.54619C4.68504 7.54619 1.68058 10.5315 1.68058 14.2088V14.9689V15.6091H2.32074H14.4248Z"
                                                fill="#3F78E0"
                                                stroke="white"
                                                strokeWidth="1.28034"
                                            />
                                        </svg>
                                    </Button>
                                </Box>
                            )}
                        </>
                    )}
                </Box>
            </Box >
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                {menuItems}
            </Drawer>
        </>
    )



}
export default Header;