import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes, } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from "./AuthContext/AuthContext.js";
import Home from "./components/Home/Home.jsx";
import Header from "./layout/Header/Header"
import Footer from "./layout/Footer/Footer.jsx"
import AddPanel from "./components/AddPanel/AddPanel.jsx"
import Providers from "./components/Providers/Providers.jsx"
import PanelsDetails from "./components/Providers/PanelsDetails.jsx"
import OurServices from './components/OurServices/OurServices.jsx';
import ServicesInvoice from "./components/OurServices/SerivcesInvoice.jsx"
import Login from './Auth/Login.jsx';
import Signup from "./Auth/Signup.jsx"
import VerifyAccount from "./Auth/VerifyAccount.jsx"
import ForgetPassswordByEmail from "./Auth/ForgetPasswordByEmail.jsx"
import VerficationPasswordByEmail from "./Auth/VerficationPasswordByEmail.jsx"
import ResetPassword from './Auth/ResetPassword.jsx';
import OurOffers from './components/Offers/OurOffers.jsx';
import OffersDetails from './components/Offers/OffersDetails.jsx';
import OffersInvoice from "./components/Offers/OffersInvoice.jsx"
import Code from "./Auth/VerifyAccount.jsx"
import Signup1 from './Auth/Signup.jsx';

function App() {
  return (
    <div className="App">
      <SnackbarProvider autoHideDuration={2000} maxSnack={1}>
        <BrowserRouter>
          <AuthProvider>
            <AppContent />
          </AuthProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </div>
  );
}
function AppContent() {

  return (
    <>

      <main className="content" >
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Header />
          <div>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Home" element={<Home />} />
              <Route path="/AddPanel" element={<AddPanel />} />
              <Route path="/Providers" element={<Providers />} />
              <Route path="/PanelsDetails/:paneluuid" element={<PanelsDetails />} />
              <Route path="/OurServices" element={<OurServices />} />
              <Route path="/ServicesInvoice" element={<ServicesInvoice />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/VerifyAccount" element={<VerifyAccount />} />
              <Route path="/ForgetPassswordByEmail" element={<ForgetPassswordByEmail />} />
              <Route path="/VerficationPasswordByEmail" element={<VerficationPasswordByEmail />} />
              <Route path="/ResetPassword" element={<ResetPassword />} />
              {/* <Route path="/ResetPassword" element={<ResetPassword />} /> */}
              <Route path="/OurOffers" element={<OurOffers />} />
              {/* <Route path="/OffersDetails/:offer_id" element={<OffersDetails />} /> */}
              <Route path="/OffersDetails/:offer_id" element={<OffersDetails />} />
              <Route path="/OffersInvoice" element={<OffersInvoice />} />
              {/* <Route path="/Code" element={<Code />} />
              <Route path="/Signup1" element={<Signup1 />} /> */}




            </Routes>
          </div>
          <Footer />
        </div>
      </main>

    </>
  );
}

export default App;
