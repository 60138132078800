import { React, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom'; // Import Link from 
import FooterLogo from "../../Assets/Svg/Footer-logo.svg"
import Whatsaap from "../../Assets/Svg/whatsaap.svg"
import Telegram from "../../Assets/Svg/Telegram.svg"
import Twitter from "../../Assets/Svg/Twitter.svg"
import Youtube from "../../Assets/Svg/youtube.svg"
import Instaram from "../../Assets/Svg/instagram.svg"
import Facebook from "../../Assets/Svg/Facebook.svg"

const Footer = () => {
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setSnackbarMessage(`Copied to clipboard: ${text}`);
                setOpenSnackbar(true);
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };
    return (
        <>
            <Box className='footer' sx={{ backgroundColor: '#357DAB', color: 'white', padding: '40px', fontFamily: "system-ui", }}>
                <img src={FooterLogo} alt='footer' />

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'center', md: 'flex-start' }, textAlign: { xs: 'center', md: 'left' }, width: { xs: "100%", lg: "80%" } }}>

                            <Typography variant="body1" letterSpacing="0.8px" sx={{ paddingTop: "10px" }}>
                                Unlock the full potential of your online presence with our tailored social media panel services. Elevate your engagement and grow your audience. Join us today and watch your social media soar!
                            </Typography>
                        </Box>
                    </Grid>


                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'center', md: 'flex-start' }, textAlign: { xs: 'center', md: 'left' } }}>
                            <Typography fontWeight="600" fontSize="16px" sx={{ mb: 1 }}>
                                Location & contact info
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'center', md: 'flex-start' }, textAlign: { xs: 'center', md: 'left' }, }}>
                                <Typography sx={{ marginTop: "20px", width: "80%" }}>
                                    3275 McCarthy Rd, Ottawa, ON K1V 9M7, Canada
                                </Typography>
                                <Typography sx={{ marginTop: "20px", width: "60%" }}>
                                    info@booksmm.com 0013439874565
                                </Typography>

                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'center', md: 'flex-start' }, textAlign: { xs: 'center', md: 'left' }, paddingLeft: "40px" }}>
                            <Typography fontWeight="600" fontSize="16px" sx={{ mb: 1 }}>
                                useful links
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'center', md: 'flex-start' }, textAlign: { xs: 'center', md: 'left' } }}>
                                <Link className='footerroute' to="/home" style={{ color: 'inherit', marginBottom: '0.5rem' }}>Services Finder</Link>
                                <Link className='footerroute' to="/Providers" style={{ color: 'inherit', marginBottom: '0.5rem' }}>Trusted providers</Link>
                                <Link className='footerroute' to="/OurServices" style={{ color: 'inherit', marginBottom: '0.5rem' }}>Our services</Link>
                                <Link className='footerroute' to="/OurOffers" style={{ color: 'inherit', marginBottom: '0.5rem' }}>Our offers</Link>
                                <Link className='footerroute' to="/AddPanel" style={{ color: 'inherit', marginBottom: '0.5rem' }}>Add panel </Link>
                                <Link className='footerroute' to="/FAQ" style={{ color: 'inherit', marginBottom: '0.5rem' }}>FAQ</Link>
                                <Link className='footerroute' to="/About" style={{ color: 'inherit', marginBottom: '0.5rem' }}>About</Link>

                            </Box>
                        </Box >
                    </Grid >

                    <Grid item xs={12} sm={12} md={2} lg={2}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'center', md: 'center' }, textAlign: { xs: 'center', md: 'left' } }}>
                            <Typography fontWeight="600" fontSize="16px" sx={{ mb: 2 }}>
                                Social links
                            </Typography>

                            <Grid container spacing={1} sx={{ marginTop: "20px" }}>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <img src={Facebook} alt='Facebook' />

                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <img src={Instaram} alt='Instaram' />

                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <img src={Twitter} alt='Twitter' />

                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <img src={Youtube} alt='Youtube' />

                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <img src={Telegram} alt='Telegram' />

                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <img src={Whatsaap} alt='Whatsaap' />

                                </Grid>

                            </Grid>


                        </Box>
                    </Grid >
                </Grid >

                {/* <Box sx={{ textAlign: 'center', marginTop: '20px', borderTop: "1px solid white" }}>
                    <Typography variant="body2" sx={{ marginTop: "25px" }}>
                        Rent chickens 2024 © All Rights Reserved | Designed and Developed by ALfahed IT
                    </Typography>
                </Box> */}
            </Box >

        </>
    )


}
export default Footer;