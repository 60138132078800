import { React, useState, useEffect } from "react";

import Typography from '@mui/material/Typography';
import { Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import Slider from '@mui/material/Slider';
import Files from "react-files";
import { Helper } from "../../tools/Helper";
import { api_Routes } from "../../api_Routes";
import { useNavigate } from 'react-router-dom';
const OurServices = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedSubcategoryDetails, setSelectedSubcategoryDetails] = useState(null);
    const [selectedCategoryDetails, setSelectedCategoryDetails] = useState(null);
    const [Categories, setCategories] = useState([]);
    const [selectedGategories, setselectedGategories] = useState("");
    const [selectedSubcategory, setSelectedSubcategory] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFont, setSelectedFont] = useState("Mockads");
    const [selectedColor, setSelectedColor] = useState("#EBF2FF");
    const [logoName, setLogoName] = useState("LOREM");
    const [fontSize, setFontSize] = useState(44);
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        customLogo: "",
        logo: null,
        panel_url: "",
    });
    const [uploadOption, setUploadOption] = useState("logo");

    const fonts = ["Mockads", "Escopedia", "Great Vibes"];
    const colors = ["#FFFFFF", "#303030", "#ECA601"];

    const handleUploadOptionChange = (option) => {
        setUploadOption(option);
        if (option === "customLogo") {
            setFormData((prev) => ({ ...prev, customLogo: "" }));
        } else {
            setFormData((prev) => ({ ...prev, logo: null }));
        }
    };

    const handleFileChange = (files) => {
        if (files && files.length > 0) {
            setFormData((prev) => ({ ...prev, logo: files[0] })); // Store the first file
        }
    };

    const handleFontChange = (font) => {
        setSelectedFont(font);
    };

    const handleColorClick = (color) => {
        setSelectedColor(color);
    };

    const handleFontSizeChange = (event, newValue) => {
        setFontSize(newValue);
    };

    const toggleUploadOption = () => {
        if (uploadOption === "logo") {
            handleUploadOptionChange("customLogo");
        } else {
            handleUploadOptionChange("logo");
        }
    };

    useEffect(() => {
        get_services();
    }, []);

    const get_services = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.OurServices.view,
            hasToken: true,
            data: { results: 100 },
        });
        if (response) {
            setCategories([]);
            setCategories(response.data.map((category) => ({
                id: category.id,
                label: category.name,
                FiledTitle: category.FiledTitle,
                FiledDescription: category.FiledDescription,
                FiledImage: category.FiledImage,
                picture: category.picture,
                description: category.description,
                subCategories: category.type.map((sub) => ({
                    id: sub.id,
                    label: sub.type,
                    price: sub.price,
                })),
            })));
        } else {
            console.log(message);
        }
    };

    const handleCategoryChange = (event) => {
        const selectedCategoryId = event.target.value;
        setselectedGategories(selectedCategoryId);

        const categoryDetails = Categories.find((category) => category.id === selectedCategoryId);
        setSelectedCategoryDetails(categoryDetails);

        setSelectedSubcategory('');
        setSelectedSubcategoryDetails(null);
    };

    const handleSubcategoryChange = (event) => {
        const selectedSubcategoryId = event.target.value;
        setSelectedSubcategory(selectedSubcategoryId);

        const subcategoryDetails = selectedCategoryDetails?.subCategories.find(
            (subCategory) => subCategory.id === selectedSubcategoryId
        );
        setSelectedSubcategoryDetails(subcategoryDetails);
    };

    const handleSubmit = async () => {
        let missingFields = [];

        // Validate panel_url
        if (!formData.panel_url || formData.panel_url.trim() === "") {
            missingFields.push("panel_url");
        }

        // Validate category
        if (!selectedGategories) {
            missingFields.push("Service name ");
        }

        // Validate subcategory
        if (!selectedSubcategory) {
            missingFields.push("service type");
        }

        // Validate title if required by the category
        if (selectedCategoryDetails?.FiledTitle && !formData.title) {
            missingFields.push("title");
        }

        // Validate description if required by the category
        if (selectedCategoryDetails?.FiledDescription && !formData.description) {
            missingFields.push("description");
        }

        // Validate image/logo requirements based on uploadOption
        if (selectedCategoryDetails?.FiledImage) {
            if (uploadOption === "logo" && !formData.logo) {
                missingFields.push("logo");
            } else if (
                uploadOption === "customLogo" &&
                (!logoName || !selectedColor || !selectedFont || !fontSize)
            ) {
                missingFields.push("customLogo properties (logoName, color, fontFamily, fontSize)");
            }
        }

        // If there are missing fields, show an error and stop submission
        if (missingFields.length > 0) {
            enqueueSnackbar(`Please fill in all required fields: ${missingFields.join(", ")}`, {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
            });
            return;
        }

        setIsLoading(true);

        let form_data = new FormData();
        form_data.append("panel_url", formData.panel_url);
        form_data.append("our_service_id", selectedGategories);
        form_data.append("our_service_type_id", selectedSubcategory);

        // Conditionally append title
        if (selectedCategoryDetails?.FiledTitle) {
            form_data.append("title", formData.title);
        }

        // Conditionally append description
        if (selectedCategoryDetails?.FiledDescription) {
            form_data.append("description", formData.description);
        }

        // Append logo or customLogo based on the uploadOption
        if (uploadOption === "logo" && formData.logo) {
            form_data.append("logo", formData.logo);
        } else if (uploadOption === "customLogo") {
            form_data.append(
                "customLogo",
                JSON.stringify({
                    fontSize: `${fontSize}px`,
                    color: selectedColor,
                    fontFamily: selectedFont,
                    logoName,
                })
            );
        }

        const { response, message } = await Helper.Post({
            url: api_Routes.OurServices.add,
            data: form_data,
            hasToken: true,
        });

        if (response) {
            const invoice_id = response.data.uuid;

            navigate(`/ServicesInvoice?invoice_id=${invoice_id}`);
        } else {
            enqueueSnackbar(message || "An error occurred", {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
            });
        }

        setIsLoading(false);
    };


    const handleChange = (key, value) => {
        setFormData((prev) => ({ ...prev, [key]: value }));
    };



    return (
        <>
            <Box sx={{ padding: { xs: "30px", md: "30px 80px" } }}>
                <Box sx={{ marginTop: "20px" }}>
                    <Typography sx={{ fontSize: "40px", lineHeight: "45px", fontWeight: "700", color: "#474747", textAlign: "center" }}>
                        Discover our exclusive services and Find the best offers
                    </Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "20px", fontWeight: "400", color: "#474747", textAlign: "center", marginTop: "30px" }}>
                        Browse all BOOKSMM services and choose the best one that suits you!
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, marginBlock: "50px", gap: "20px" }}>
                    <Box sx={{ width: { xs: "100%", md: "50%" } }}>
                        <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center" }}>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                        boxShadow: '1px 2px 4px #454040',
                                        borderRadius: "8px",
                                        background: "#F5F8FE",
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: "none",
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#6B9DFE',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#007BFF',
                                            },
                                        },
                                    }}
                                >
                                    <InputLabel
                                        id="select-category-label"
                                        sx={{
                                            color: "#474747",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            lineHeight: "20px",
                                            '&.Mui-focused': {
                                                color: '#007BFF',
                                            },
                                        }}
                                    >
                                        Service
                                    </InputLabel>
                                    <Select
                                        labelId="select-category-label"
                                        id="select-category"

                                        value={selectedGategories}
                                        onChange={handleCategoryChange}
                                        displayEmpty
                                        label="Select Category"
                                        sx={{
                                            '& .MuiSelect-select': {
                                                padding: '10px 12px',

                                                display: 'flex',
                                                alignItems: 'center',
                                            },
                                            '& .MuiSelect-icon': {
                                                color: '#707275',
                                            },
                                            '& .MuiMenu-paper': {
                                                maxHeight: '200px',
                                                backgroundColor: '#f5f8fe',
                                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                                borderRadius: '8px',
                                                '& .MuiMenuItem-root': {
                                                    padding: '10px',
                                                    color: '#333',
                                                    fontSize: '14px',
                                                    borderBottom: '1px solid #9E9E9E',
                                                    '&[aria-selected="true"]': {
                                                        backgroundColor: '#007bff',
                                                        color: '#fff',
                                                    },
                                                    '&[data-focus="true"]': {
                                                        backgroundColor: '#007bff',
                                                    },
                                                },
                                            },
                                            '&::-webkit-scrollbar': {
                                                width: '8px',
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                background: '#F5F8FE',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                background: '#004571',
                                                borderRadius: '4px',
                                            },
                                            '&::-webkit-scrollbar-thumb:hover': {
                                                background: '#004571',
                                            },
                                        }}
                                        inputProps={{
                                            'aria-label': 'Select Category',
                                        }}
                                    >
                                        {/* Replace Categories with your actual category data array */}
                                        {Categories.map((option) => (
                                            <MenuItem key={option.id} value={option.id} sx={{ padding: '10px', color: '#333', fontSize: '14px', borderBottom: '1px solid #9E9E9E' }}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={5}>
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                        boxShadow: '1px 2px 4px #454040',
                                        borderRadius: "8px",
                                        background: "#F5F8FE",
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: "none",
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#6B9DFE',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#007BFF',
                                            },
                                        },
                                    }}
                                >
                                    <InputLabel
                                        id="select-subcategory-label"
                                        sx={{
                                            color: "#474747",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            lineHeight: "20px",
                                            '&.Mui-focused': {
                                                color: '#007BFF',
                                            },
                                        }}
                                    >
                                        Type
                                    </InputLabel>
                                    <Select
                                        labelId="select-subcategory-label"
                                        id="select-subcategory"
                                        value={selectedSubcategory}
                                        onChange={handleSubcategoryChange}
                                        displayEmpty
                                        label="Select Subcategories"
                                        sx={{
                                            '& .MuiSelect-select': {
                                                padding: '10px 12px',

                                                display: 'flex',
                                                alignItems: 'center',
                                            },
                                            '& .MuiSelect-icon': {
                                                color: '#707275',
                                            },
                                            '& .MuiMenu-paper': {
                                                maxHeight: '200px',
                                                backgroundColor: '#f5f8fe',
                                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                                borderRadius: '8px',
                                                '& .MuiMenuItem-root': {
                                                    padding: '10px',
                                                    color: '#333',
                                                    fontSize: '14px',
                                                    borderBottom: '1px solid #9E9E9E',
                                                    '&[aria-selected="true"]': {
                                                        backgroundColor: '#007bff',
                                                        color: '#fff',
                                                    },
                                                    '&[data-focus="true"]': {
                                                        backgroundColor: '#007bff',
                                                    },
                                                },
                                            },
                                            '&::-webkit-scrollbar': {
                                                width: '8px',
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                background: '#F5F8FE',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                background: '#004571',
                                                borderRadius: '4px',
                                            },
                                            '&::-webkit-scrollbar-thumb:hover': {
                                                background: '#004571',
                                            },
                                        }}
                                    >
                                        {/* Replace getSubCategories() with your actual function or array */}
                                        {selectedCategoryDetails && selectedCategoryDetails.subCategories.map((option) => (
                                            <MenuItem
                                                key={option.id}
                                                value={option.id}
                                                sx={{
                                                    padding: '10px',
                                                    color: '#333',
                                                    fontSize: '14px',
                                                    borderBottom: '1px solid #9E9E9E',
                                                }}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                        {/* {getSubCategories().map((option) => (
                                            <MenuItem
                                                key={option.id}
                                                value={option.id}
                                                sx={{
                                                    padding: '10px',
                                                    color: '#333',
                                                    fontSize: '14px',
                                                    borderBottom: '1px solid #9E9E9E',
                                                }}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))} */}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={11}>
                                {/* Category and Subcategory Details Box */}
                                <Box sx={{
                                    border: "1px solid #5F5F5F", height: "12rem", display: "flex", flexDirection: "column", justifyContent: "space-around", padding: "14px 20px",
                                    boxShadow: "-2px 2px 4px #00000040", borderRadius: "8px"
                                }}>
                                    {selectedCategoryDetails ? (
                                        <>
                                            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                                <Typography sx={{ fontWeight: "500", fontSize: "20px", lineHeight: "25px", color: "#5E5B5B" }}>
                                                    {selectedCategoryDetails.label}
                                                </Typography>
                                                <Typography sx={{ fontWeight: "400", fontSize: "14px", lineHeight: "18px", color: "#5E5B5B" }}>
                                                    {selectedCategoryDetails.description}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography sx={{ fontWeight: "500", fontSize: "20px", lineHeight: "24px", color: "#5E5B5B" }}>
                                                    Price: {selectedSubcategoryDetails ? selectedSubcategoryDetails.price : 'Please Select Subgategories'} $
                                                </Typography>
                                            </Box>
                                        </>
                                    ) : (
                                        <Typography>Please select a category to see details</Typography>
                                    )}
                                </Box>
                            </Grid>
                            {selectedCategoryDetails?.FiledTitle && (
                                <Grid item xs={12} sm={12} md={11}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { color: "#434343", fontSize: "13px", padding: "5px" },
                                        }}
                                        value={formData.title}
                                        onChange={(e) => handleChange("title", e.target.value)}
                                        name="title"
                                        placeholder="Type Title"
                                        sx={{
                                            background: "white",
                                            borderRadius: "8px",
                                            "& .css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input": {
                                                padding: "10px",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                border: "1px solid #5F5F5F",
                                                boxShadow: "-2px 2px 4px #00000040",
                                                borderRadius: "8px",
                                                "& fieldset": {
                                                    border: "none",
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                            )}

                            {selectedCategoryDetails?.FiledDescription && (
                                <Grid item xs={12} sm={12} md={11}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        multiline
                                        minRows={1}
                                        InputLabelProps={{
                                            style: { color: "#434343", fontSize: "13px" },
                                        }}
                                        value={formData.description}
                                        onChange={(e) => handleChange("description", e.target.value)}
                                        name="description"
                                        placeholder="Add your description here..."
                                        sx={{
                                            background: "white",
                                            borderRadius: "8px",
                                            "& .MuiOutlinedInput-root": {
                                                border: "1px solid #5F5F5F",
                                                boxShadow: "-2px 2px 4px #00000040",
                                                borderRadius: "8px",
                                                "& fieldset": {
                                                    border: "none",
                                                },
                                                "& textarea": {
                                                    borderRadius: "10px",
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} sm={12} md={11}>

                                <TextField
                                    fullWidth
                                    variant="outlined"

                                    // sets the initial height
                                    InputLabelProps={{
                                        style: { color: "#434343", fontSize: "13px", padding: "5px" },
                                    }}
                                    // onChange={(e) => {
                                    //     handleChange("description", e.target.value);
                                    // }}
                                    value={formData.panel_url}
                                    onChange={(e) => { handleChange("panel_url", e.target.value) }}
                                    name="panel_url"
                                    placeholder="Put your Link here ... "
                                    sx={{
                                        background: "white",
                                        borderRadius: "8px",
                                        "& .css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input": {
                                            padding: "10px"


                                        },
                                        "& .MuiOutlinedInput-root": {

                                            border: "1px solid #5F5F5F",
                                            boxShadow: "-2px 2px 4px #00000040",
                                            borderRadius: "8px",
                                            "& fieldset": {
                                                border: "none"
                                            },

                                        },
                                    }}
                                />
                            </Grid>

                        </Grid>

                    </Box >




                    <Box sx={{ width: { xs: "100%", md: "50%" } }} >
                        {selectedCategoryDetails?.picture ? (
                            // If selectedCategoryDetails.picture exists, show the picture
                            <img
                                src={selectedCategoryDetails.picture}
                                alt="Category"
                                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                            />
                        ) : (
                          
                            <svg
                                width="608"
                                height="355"
                                viewBox="0 0 608 355"
                                fill="none"
                                style={{ width: "100%", height: "100%" }}
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect x="0.5" width="607" height="355" rx="7.27" fill="#ECECEC" />
                            </svg>
                        )}
                    </Box>

                </Box>
                {selectedCategoryDetails?.FiledImage && (
                    <Box sx={{ width: { xs: "100%", sm: "100%" } }}>
                        <Typography

                            sx={{ marginBottom: "10px", fontWeight: "600", fontSize: "24px", lineHeight: "30px", color: "#474747" }}
                        >
                            Logo Image *
                        </Typography>

                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "20px 0" }}>

                            <Button variant="outlined" onClick={toggleUploadOption} sx={{
                                fontWeight: "600",
                                border: "1px solid #3F78E0",
                                textTransform: "capitalize",
                                textAlign: "center",
                                borderRadius: "10px",
                                padding: "3px 40px",
                                background: "#FFFFFF",
                                color: "#3F78E0",
                                boxShadow: "0px 5px 5px #00000040, inset 4px 7px 5px #FFFFFF30",
                                "&:hover": {
                                    background: "none", // Optional: Darken background on hover
                                },
                            }}>

                                {uploadOption === "logo" ? "Switch to Custom Logo" : "Switch to Photo Upload"}
                            </Button>
                        </Box>
                        <Box sx={{
                            display: "flex", flexDirection: { xs: "column", md: "row" }, position: "relative", justifyContent: "space-between", width: "100%",
                            gap: { xs: "20px", md: 0 }
                        }}>
                            <Box
                                sx={{
                                    width: { xs: "100%", md: "45%" },
                                    opacity: uploadOption === "customLogo" ? 0.5 : 1,
                                    pointerEvents: uploadOption === "customLogo" ? "none" : "auto",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        borderRadius: "10px",
                                        alignItems: "center",
                                        height: { xs: "300px", md: "100%", lg: "300px" },
                                        textAlign: "center",
                                        backgroundColor: "#FFFFFF",
                                        cursor: uploadOption === "customLogo" ? "not-allowed" : "pointer",
                                        position: "relative",
                                        border: "#303030 solid 1px",
                                        borderRadius: "15px",

                                    }}
                                >
                                    <Files
                                        onChange={(fileList) => handleFileChange(fileList)}
                                        onError={(error) => console.error("File upload error:", error)}
                                        accepts={["image/*"]}
                                        clickable
                                        multiple={false}
                                        maxFileSize={10485760} // 10MB limit
                                        disabled={uploadOption === "customLogo"}
                                        style={{
                                            opacity: 0,
                                            position: "absolute",
                                            width: "100%",
                                            height: "100%",
                                            top: 0,
                                            left: 0,
                                        }}
                                    />
                                    {formData.logo ? (
                                        <Box
                                            sx={{
                                                position: "relative",
                                                width: "80%", // Adjust the size of the image
                                                height: "80%",
                                                borderRadius: "10px",
                                                overflow: "hidden",
                                            }}
                                        >
                                            <img
                                                src={URL.createObjectURL(formData.logo)}
                                                alt="Uploaded Logo"
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "contain", // Ensure the image is properly contained
                                                    borderRadius: "10px",
                                                }}
                                            />
                                            <IconButton
                                                onClick={() => {
                                                    setFormData((prev) => ({ ...prev, logo: null }));
                                                }}
                                                sx={{
                                                    position: "absolute",
                                                    top: "5px",
                                                    right: "5px",
                                                    color: "white",
                                                    backgroundColor: "red",
                                                    borderRadius: "10px",
                                                    '&:hover': {
                                                        backgroundColor: "red",
                                                    },
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>
                                    ) : (
                                        <Typography variant="body2" color="textSecondary">
                                            Click to upload a logo
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                            {/* <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        marginBottom: "10px",
                                        fontSize: "16px",
                                        color: "#5F5F5F",
                                        fontWeight: "600",
                                    }}
                                >
                                    Switch
                                </Typography>
                                <Box sx={{ display: "flex", alignItems: "center", margin: "20px 0" }}>
                                    <Button
                                        variant="contained"
                                        onClick={toggleUploadOption}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "8px",
                                            padding: "4px 8px",
                                            backgroundColor: "#1976d2",
                                            color: "white",
                                            fontWeight: "bold",
                                            "&:hover": { backgroundColor: "#155a9b" },
                                        }}
                                    >
                                        {uploadOption === "logo" ? (
                                            <>
                                                <ArrowBackIosIcon fontSize="small" />
                                                Custom Logo
                                            </>
                                        ) : (
                                            <>
                                                logo Upload
                                                <ArrowForwardIosIcon fontSize="small" />
                                            </>
                                        )}
                                    </Button>
                                </Box>
                            </Box> */}
                            <Box sx={{
                                width: { xs: "100%", md: "50%" },
                                opacity: uploadOption === "logo" ? 0.5 : 1,
                                pointerEvents: uploadOption === "logo" ? "none" : "auto",
                                background: "#FFFFFF", border: "1px solid #303030", borderRadius: "10px",
                            }}>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", sm: "row", md: "column", lg: "row" },
                                    justifyContent: { xs: "space-between", sm: "space-around", md: "space-between" }, alignItems: "center",
                                    height: "100%",
                                    padding: { xs: "20px 20px", sm: "0 20px", md: "20px 20px", lg: "0 20px" },

                                }}>
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", width: "90%", }}>
                                        <Box>
                                            <Typography sx={{ color: "#474747", fontSize: "20px", fontWeight: "600", lineHeight: "30px", }}>
                                                Pick a Custom Font
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                value={logoName}
                                                onChange={(e) => setLogoName(e.target.value)}
                                                placeholder="Type Your Logo Name here"
                                                size="small"
                                                InputLabelProps={{
                                                    style: {
                                                        fontSize: "16px", color: "#5F5F5F",
                                                        lineHeight: "24px", fontWeight: "600"
                                                        , letterSpacing: "1px"
                                                    },
                                                }}
                                                sx={{
                                                    width: "95%",
                                                    boxShadow: "-2px 2px 4px #00000040",
                                                    borderRadius: "10px",
                                                    backgroundColor: "white",
                                                    marginTop: "10px",

                                                }}
                                            />
                                        </Box>

                                        <Box>
                                            <Typography>Choose a Font</Typography>
                                            <Box sx={{ display: "flex", flexDirection: "row", gap: "10px", marginTop: "4px", justifyContent: "space-evenly" }}>
                                                {fonts.map((font) => (
                                                    <Box
                                                        key={font}
                                                        sx={{
                                                            background: "#E5EDFF",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            flexDirection: "column",
                                                            gap: "4px",
                                                            border: "1px solid #959595",
                                                            borderRadius: "10px",
                                                            padding: { xs: "4px 6px", sm: "4px 12px", md: "4px 25px" },
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontFamily: font,
                                                                fontSize: "20px",
                                                                lineHeight: "24px",
                                                                fontWeight: "600",
                                                                color: "#474747",
                                                            }}
                                                        >
                                                            lorem
                                                        </Typography>
                                                        <Radio
                                                            checked={selectedFont === font}
                                                            onChange={() => handleFontChange(font)}
                                                            sx={{
                                                                color: 'primary.main',
                                                            }}
                                                        />
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Box>
                                        <Typography>
                                            Choose a color and font size
                                        </Typography>
                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                                            <Box sx={{ display: "flex", flexDirection: "column", gap: "6px" }}>
                                                <Box sx={{ display: "flex", gap: 2 }}>
                                                    {colors.map((color) => (
                                                        <Box
                                                            key={color}
                                                            onClick={() => handleColorClick(color)}
                                                            sx={{
                                                                width: 20,
                                                                height: 20,
                                                                borderRadius: "50%",
                                                                backgroundColor: color,
                                                                cursor: "pointer",
                                                                border: "1px #303030 solid",
                                                                transition: "border 0.3s ease",
                                                            }}
                                                        />
                                                    ))}
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: "column", gap: "6px", }}>
                                                <Slider
                                                    value={fontSize}
                                                    onChange={handleFontSizeChange}
                                                    min={20}
                                                    max={50}
                                                    step={1}
                                                    valueLabelDisplay="auto"
                                                    sx={{ width: "100px" }}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "6px", height: "200px" }}>
                                        <Typography sx={{ fontSize: "18px", lineHeight: "28px", color: "#474747", }}>
                                            Your Logo demo
                                        </Typography>
                                        <Box sx={{
                                            background: "radial-gradient(circle, #51AAFD, #002D68)", display: "flex",
                                            justifyContent: "center", alignItems: "center", position: "relative", padding: { xs: "40px", sm: "40px 20px", md: "40px" }, borderRadius: "15px",
                                            width: "100px"
                                        }}>
                                            <Typography sx={{
                                                fontSize: `${fontSize}px`, fontWeight: "700", lineHeight: "50px", color: "#EBF2FF",
                                                color: selectedColor,
                                                fontFamily: selectedFont,
                                            }}>
                                                {logoName || "LOREM"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                )}
                <Box sx={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            textTransform: "none",
                            whiteSpace: 'nowrap',
                            background: "#ECA601",
                            padding: "10px 50px",
                            borderRadius: '10px',
                            // Adding inner shadow using a pseudo-element
                            color: "#F1F5FD",
                            position: 'relative',
                            lineHeight: "20px",
                            overflow: 'hidden',
                            fontWeight: "700",
                            fontWeight: "700",
                            fontSize: "18px",
                            boxShadow: "0px 3px 3px #00000040", // Outer shadow
                            '&:before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                borderRadius: '0 20px 20px 0',
                                background: 'rgba(255, 255, 255, 0.1)', // Color for the inner shadow
                                filter: 'blur(4px)',
                                zIndex: 1,
                            },
                            '&:hover:before': {
                                background: 'rgba(255, 255, 255, 0.2)', // Darker on hover for inner shadow
                            },
                        }}
                        onClick={handleSubmit}
                    >
                        {isLoading ? (
                            <CircularProgress size={22} sx={{ color: "white" }} />
                        ) : (
                            "Submit"
                        )}
                    </Button>


                </Box>




            </Box >


        </>

    )

}
export default OurServices;